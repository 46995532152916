body {
    margin: 0 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
}

#root {
    overscroll-behavior-y: none;
}

* {
    box-sizing: border-box;
}

/* scroll bar */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #657080;
    border-radius: 5px;
}

code,
kbd,
samp,
pre {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:focus {
    outline: none;
}
/**
 * needed for safari. Safari
 * adds background color on its own
 * on hls-viewer on fullscreen
 */
#hls-viewer-dark:fullscreen {
    background-color: black !important;
}

#hls-viewer-light:fullscreen {
    background-color: white !important;
}
.mr-10 {
    margin-right: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}

.pr-10 {
    padding-right: 10px;
}
.pl-10 {
    padding-left: 10px;
}
.pt-10 {
    padding-top: 10px;
}
.pb-10 {
    padding-bottom: 10px;
}

/* custome-checkbox-design */
/* The container */
.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 20px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #2e2e41;
    border: 1.4px solid #676876;
    border-radius: 0.2em;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    opacity: 0.5;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #2e2e41;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: calc(50% - 2px);
    top: calc(50% - 4px);
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* custome-checkbox-design */
.hms-ui-c-PJLV-ihWtKLf-css {
    background-color: #191924 !important;
}

.muteLogo {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
}

.autocomplete {
    height: 25px;
    margin: 10px 10px 10px 8px;
    background-color: #191924;
}

.autocompleteLabel {
    color: white;
    background-color: #191924;
    position: relative;
}
.btn {
    background-color: #13b1b6;
    color: white;
    font-size: 12px;
    padding: 0px;
    margin-left: 10px;
    justify-content: flex-end;
    border-radius: 3px;
    width: auto;
    height: 34px;
}
.run-code-btn {
    background-color: #13b1b6;
    color: white;
    font-size: 12px;
    border-radius: 3px;
    height: 34px;
    width: auto;
    display: flex;
    align-items: center;
    padding: 8px;
}

.run-code-btn > img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.code-editor-btn > img {
    width: 14px;
    height: 14px;
    margin: 10px;
}
.hms-ui-c-PJLV-igqoVxK-css {
    width: 70%;
    height: calc(100% - 5px);
}
.hms-ui-c-dhzjXW-idCZqOY-css {
    width: 70%;
    height: calc(100% - 5px);
    background: rgb(0, 0, 0);
}
.video-wrapper {
    width: 100%;
}
.videoGrid {
    background-color: #191924;
    padding-top: 20px;
}
.video-grid-header {
    background-color: #2e2e41;
    padding: 10px 10px 5px 5px;
    border-radius: 8px 8px 0px 0px;
}
.minimise-btn {
    background: transparent;
    border: none;
}
.expand-btn {
    background: transparent;
    border: none;
}
.vid-vertical-wrapper {
    margin-left: 10px;
    margin-right: 10px;
}
.individual-img-div {
    padding: 10px;
    background-color: #c4c4c4;
    min-height: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.name-div {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background-color: #2e2e41;
}
.btn:hover {
    background-color: #13b1b6;
}
.lang {
    margin: 20px 10px 10px 15px;
    font-weight: 500;
}
.playground {
    background-color: #2e2e41;
}
.io-playground {
    height: fit-content;
    background-color: #13b1b6;
}
.io-container {
    margin-top: 20px;
    background-color: #191924;
}
.textField {
    background-color: #2e2e41;
    color: #ffffff;
    width: 100%;
    border: 1px solid #000000;
}
.feedback-page {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
}
.final-remarks-page {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    height: calc(100% - 40px);
}
.fp-title {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    padding-bottom: 10px;
}
.fp-content-container {
    margin-top: 5px;
}
.instruction-text {
    color: #fff;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 16px;
    text-align: left;
}
@media only screen and (max-width: 1369px) {
    .fr-checkbox-container > label {
        margin: 5px 0;
    }
}
.fr-submit-btn {
    border: none;
    background-color: #13b1b6;
    padding: 5px 30px;
    font-size: 12px;
    border-radius: 0.4em;
}
.fr-submit-btn:disabled {
    background-color: #626262;
}
.fr-submit {
    margin: 20px 0px;
}
.skill-input {
    font-size: 13px;
    margin-bottom: 12px;
}
.delete-skill {
    background-color: #2e2e41;
    border-radius: 5px;
    height: 30px;
    margin: 0 0.5%;
    position: absolute;
    width: 10%;
    right: 0;
    top: 4px;
    z-index: 1;
}
.si-row-2 {
    position: relative;
    margin-top: 10px;
}
.delete-img {
    height: auto;
    width: 12px;
}
.delete-btn-v2 {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 10px;
}
.delete-img-v2 {
    height: auto;
    width: 12px;
    vertical-align: middle;
}
.question-input {
    height: 38px;
    background-color: transparent;
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    color: #ffffff;
    width: 89%;
    padding: 5px 10px;
    font-size: 11px;
}
.question-input2 {
    height: 38px;
    background-color: transparent;
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    color: #ffffff;
    width: 100%;
    padding: 5px 10px;
    font-size: 11px;
}
.skill-remarks {
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    background-color: transparent;
    height: 70px;
    padding: 5px 10px;
    width: 100%;
    color: #fff;
    padding-right: 20px;
}
.final-remarks {
    height: 100px;
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px 10px;
    font-size: 11px;
    outline: none;
    width: 100%;
    font-style: normal;
    font-weight: 275;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #9fa2ac;
    padding-right: 40px;
}
.css-b62m3t-container {
    color: white;
}
.w-68 {
    width: 67%;
}
.w-85 {
    width: 78%;
}
.css-1s2u09g-control {
    background-color: transparent;
    border: 1.2px solid #9b9b9b;
    min-height: 36px;
}
.css-14el2xx-placeholder {
    color: #ffffff;
}
.css-1okebmr-indicatorSeparator {
    visibility: hidden;
}
.skill-rating {
    background-color: initial;
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    color: #fff;
    float: left;
    height: 38px;
    margin: 0 1%;
    width: 20%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    font-family: 'Manrope-Bold';
}

.custom-select {
    position: relative;
    font-family: Arial;
}

.custom-select select {
    display: none;
}

.select-selected {
    background-color: DodgerBlue;
}
.select-selected:after {
    position: absolute;
    content: '';
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.main-container {
    background-color: #191924;
}
.main-logo {
    height: 40px;
    width: auto;
}
.part-1 {
    width: 65%;
    padding: 10px;
}
.p1h-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.content-name {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.activity-container {
    height: 100% !important;
}
.inactive-tab {
    background-color: #20202d;
    color: #a59696;
}
svg > path {
    fill: #a59696;
}
.active-tab {
    background-color: #46b2b7 !important;
    color: #fff !important;
}

svg > path {
    fill: #ffffff;
}

.tab-rad {
    border-top-left-radius: 5px;
}
.activity-tab {
    width: 6.4vw;
    height: 3.32vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: inline-flex;
    margin: 0px 2px;
    color: #fff;
    padding: 6px 12px;
    border: 1px solid #000;
    background: #20202d;
    width: auto;
    gap: 4px;
}
.tab-logo {
    margin-right: 5px;
    height: 1.75vh;
    width: 1vw;
}

.tab-text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.75vw;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
}

.margin-1 {
    margin: 0 1px;
}
.activity-board-wrapper {
    height: 94%;
}

.activity-board {
    height: calc(100% - 45px);
    background-color: #2e2e41;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    color: white;
    position: relative;
}

.part-header {
    background-color: #2e2e41;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    height: 8vh;
    border-radius: 8px;
    position: relative;
}
.candi-details-box {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    /* justify-content: space-between; */
    align-items: center;
}
.divider {
    height: 10px;
    width: 1.5px;
    background-color: white;
}

.candi-doc-img {
    height: 10px;
    width: auto;
    margin-right: 5px;
}

.fp-span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16vw;
    align-items: center;
    text-align: center;
}
.btn-wrapper {
    width: 25%;
}
.end-call-btn {
    border: none;
    background-color: #ec2d38;
    height: 34px;
    padding: 0 10px;
}

.phone-img {
    margin-right: 10px;
    height: auto;
    width: 20px;
}

.feedback-btn-container {
    height: 7vh;
    display: flex;
    z-index: 2;
    position: relative;
}

.feedback-btn {
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 10px 10px 0px 0px;
    width: 100%;
}

.feedback-img {
    height: 20px;
    width: auto;
}
.feedback-page-container {
    width: 100%;
    overflow-wrap: break-word;
    height: calc(100% - 7vh);
    background-color: #2e2e41;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    padding: 0px 10px;
}
.feedback-page-container-feed {
    width: 100%;
    overflow-wrap: break-word;
    height: calc(100% - 7vh);
    background-color: #2e2e41;
    overflow-y: auto;
    position: relative;
    z-index: 1;
}
.code-editor-btn:disabled {
    background-color: #626262 !important;
}

.plain-header-div {
    padding-top: 10px;
    padding-left: 15px;
}

.videoGrid {
    background: #2e2e41;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    position: relative;
}
.video-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    padding: 0px 10px;
}
.video-btn-div {
    position: absolute;
    right: 5px;
}
.video-m-btn {
    border: none;
    padding: 0px 4px;
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 5px;
    margin: 5px 10px;
}

.minimise-btn {
    border: none;
    margin: 0px 2px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
    width: 36px;
    height: 36px;
    background: #2d3440;
    border-radius: 5px;
}
.expandArrowImg {
    width: 100%;
}

.expand-btn {
    background: transparent;
    border: none;
}

.video-tile-wrapper {
    height: 100%;
}

.vid-vertical-wrapper {
    margin-left: 10px;
    margin-right: 10px;
}

.video-grid-footer-wrapper {
    width: 100%;
    bottom: 20px;
    position: absolute;
}

.conference-section {
    text-align: center;
    margin: 10px auto;
}

.screenType {
    display: inline-block;
    margin: 0.2%;
}
.screenWidth1 {
    width: 80.5%;
}
.screenWidth2 {
    width: 49.5%;
}
.screenWidth2 {
    width: 32.5%;
}

.peer-video {
    width: 100%;
}

.tileType {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.peer-div {
    padding: 2px 5px;
    position: absolute;
    background: #626262;
    border-radius: 5px;
    right: 10px;
    bottom: 10px;
}

.chat-div {
    width: 45%;
    height: 96%;
    background: #000000;
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    right: 0;
    margin: 2%;
}

.chat-input {
    background: rgba(223, 223, 223, 0.1);
    backdrop-filter: blur(10.1948px);
    border-radius: 10.1948px;
    border: none;
    width: 80%;
    padding: 5px 10px;
    margin-bottom: 5px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    left: 10px;
    bottom: 10px;
    position: absolute;
}

.chat-btn {
    border: none;
    width: 12%;
    height: 34px;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    position: absolute;
    right: 10px;
}

.chat-btn img {
    width: 20px;
}

.chat-message-other {
    background: #2e2e41;
    border-radius: 8px 8px 8px 0;
    width: 70%;
    float: left;
    color: #fff;
    margin: 10px;
    padding: 5px;
}
.chat-message-own {
    background: #13b1b6;
    border-radius: 8px 8px 0 8px;
    width: 70%;
    float: right;
    color: #fff;
    margin: 10px;
    padding: 5px;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('./fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('./fonts/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraLight';
    src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Light';
    src: url('./fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('./fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url('./fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    width: 100%;
    height: 100%;
    background-color: #191924;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

#root {
    height: 100%;
    width: 100%;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c0c4cd;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c0c4cd;
}

.f-reg {
    font-family: 'Manrope-Regular';
}

.f-sem-bol {
    font-family: 'Manrope-SemiBold';
}

.f-med {
    font-family: 'Manrope-Medium';
}

.f-lig {
    font-family: 'Manrope-Light';
}

.f-ex-lig {
    font-family: 'Manrope-ExtraLight';
}

.f-ex-bol {
    font-family: 'Manrope-ExtraBold';
}

.f-bol {
    font-family: 'Manrope-Bold';
}

.f-share {
    font-family: 'Share Tech', sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

.f-10 {
    font-size: 10px;
}

.f-11 {
    font-size: 11px;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-2v {
    font-size: 2vw;
}

.f-3v {
    font-size: 3vw;
}

.f-5v {
    font-size: 5vw;
}

.f-7v {
    font-size: 7vw;
}

.f-9v {
    font-size: 9vw;
}

.f-30 {
    font-size: 5vw;
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-theme-white {
    color: #ffffff;
}

.text-theme-red {
    color: #ec2d38;
}

.text-theme-gray {
    color: #9fa2ac;
}

.theme-gray-bg {
    background-color: #20202d;
}

.theme-peacock-bg {
    background-color: #13b1b6;
}

.whiteboard {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 96%;
    height: 50vh;
    padding: 2%;
}

.meeting-tab {
    text-align: end;
    width: 70%;
    position: absolute;
    top: 32px;
}

.candidate-meeting-tab {
    text-align: end;
    width: 75%;
    position: absolute;
    top: 32px;
}

.candidate-meeting-tab2 {
    text-align: end;
    width: 100%;
    position: absolute;
    top: 32px;
}

.text-left {
    text-align: left;
}

.earlyjoin-p {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6vw;
    line-height: 41px;
    color: #ffffff;
    text-align: center;
    margin: 15px;
}

.flip-countdown-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 20px;
    text-align: center;
    color: #f2f2f2;
    margin: 10px;
    display: block;
}

.early-join-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
}

.flip-countdown-card .card__top,
.flip-countdown-card .card__bottom {
    background: #12b6bc !important;
    border-radius: 7px !important;
    color: #fff !important;
}

.verification-p {
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    line-height: 4vw;
    color: #ffffff;
    text-align: center;
    margin: 2vw;
}

.verification-otp-div {
    text-align: center;
    justify-content: center;
    margin: 40px auto;
    display: flex;
}

.tnc-container {
    background: rgba(255, 255, 255, 0.2);
    /* height: 80vh; */
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
}
.tnc-wrapper {
    z-index: 1000;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 24px;
    position: absolute;
    width: 40vw;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    background: #13161b;
    border: 1px solid #1b1f26;
    border-radius: 16px;
    justify-content: center;
    max-width: 460px;
}

.tnc-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    width: 100%;
    background: #1e232a;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    flex: none;
    order: 0;
    flex-grow: 1;
}

.tnc-content-span {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #f5f9ff;
}

.tnc-content-header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #f5f9ff;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tnc-content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #f5f9ff;
    flex: none;
    order: 2;
    flex-grow: 1;
    margin: 2px 30px;
    position: absolute;
}

input[type='checkbox'] {
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
    padding: 10px;
}

.tcn-accetp-btn {
    box-sizing: border-box;
    padding: 3px 20px;
    width: 5vw;
    height: 3.5vh;
    background: #12b6bc !important;
    border: 1px solid #12b6bc !important;
    color: rgba(255, 255, 255, 0.98) !important;
    border-radius: 2px !important;
    float: right;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 0.8vw !important;
    line-height: 24px !important;
}
.tcn-accetp-btn:disabled {
    background-color: #626262 !important;
    border-color: #626262 !important;
}
.tcn-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tnc-span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #f5f9ff;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tnc-header {
    align-items: initial;
    flex-direction: initial;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cancelled-p {
    font-style: normal;
    font-weight: 500;
    font-size: 43px;
    line-height: 59px;
    text-align: center;
    color: #ffffff;
    padding: 50px 0px;
}

.cancelled-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
}

.feedback-wrapper-new {
    background-color: #f5f5f5;
    z-index: 1;
    margin: 0 auto;
    align-items: center;
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.feeback-collection {
    max-width: 512px;
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 20px;
}

.feeback-collection-header {
    font-style: normal;
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #000000;
}

.star-btn {
    padding: 10px 10px 10px 0px;
    background: initial;
}

.feedback-input {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
    height: 80px;
    margin: 10px 0px;
}
.feedback-submit {
    width: 3.8vw;
    height: 5vh;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #0092dc;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75vw;
    line-height: 132%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
}

.feedback-inner-head {
    align-items: center;
    color: #000000;
    font-size: 1.5vw;
    margin-bottom: 15px;
}

.feedback-inner-div {
    margin-top: 10px;
}

.feedback-close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
}

.error-content-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 80vh;
    text-align: center;
    margin: 0 auto;
}

.error-subtitle {
    font-size: 15px;
    color: #ffffff;
    padding: 20px 5px;
}
.error-title {
    font-size: 2rem !important;
    color: #ffffff;
}

.main-codeEditor {
    height: 100%;
    width: 100%;
    background: #2e2e41;
    border-radius: 0px;
    padding: 10px;
}

.code-editor-div {
    display: inline-block;
    margin: 10px;
    height: 100%;
    width: 100%;
}
.code-editor-div-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    height: 100%;
    width: 100%;
}

.code-editor-div2 {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 1%;
}

.code-editor-div3 {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    margin: 0 1%;
}

.code-editor-div textarea,
.code-editor-div-2 textarea {
    background: #191925;
    border: 1px solid #000000;
    padding: 10px;
    color: #ffffff;
    width: 100%;
    height: calc(100% - 48px);
}

.srcSpan {
    margin: 10px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #ffffff;
    display: inline-block;
}

.code-editor-main-div {
    height: calc(100% - 40px);
    display: flex;
}

.editor-div {
    height: calc(100% - 23px);
    background: #191924;
}

.cd-btn-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cd-input-div {
    height: 50%;
}

.code-editor-select-input {
    background: #3c3c3c;
    border-radius: 5px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    padding: 10px;
    display: inline-block;
    margin-right: 10px;
}
.hms-ui-c-PJLV-ieFxTgs-css {
    min-height: auto !important;
}
.hms-ui-c-cbtCOa {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.code-editor-span {
    display: inline-block;
    margin-right: 10px;
}

.cd-dub-div {
    /* height: 40px; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.select-input-container {
    color: #fff;
    display: inline-block;
    text-align: left;
    cursor: pointer;
}
.css-w9q2zk-Input2 {
    cursor: pointer;
}
.css-1nmdiq5-menu > div {
    height: 150px;
    /* &::-webkit-scrollbar-thumb {
    background-color: #13b1b6;
    border-radius: 5px;
  } */
}
.css-13cymwt-control,
.css-t3ipsp-control,
.css-1jqq78o-placeholder {
    background: #2e2e41 !important;
    color: #ffffff;
    background-color: #2e2e41 !important;
}

.css-13cymwt-control {
    border: 1px solid #9b9b9b !important;
}
.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue,
.css-olqui2-singleValue {
    color: #ffffff !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    font-family: 'Manrope-Bold';
}
.css-16xfy0z-control {
    background-color: inherit !important;
}

.part-2 {
    background: #191924;
    padding: 1vh;
    height: 100%;
}
.comment-div {
    background: #13b1b6;
    border-radius: 5px;
    width: 10%;
    float: left;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5%;
}

.si-row-1 {
    padding: 0px 0px 5px 0px;
    height: 43px;
    gap: 3px;
    display: flex;
}

.preview-div .hms-ui-c-cHVKOV-egOeBJ-active-false {
    background: #f5f9ff !important;
}

.preview-div .hms-ui-c-cHVKOV-egOeBJ-active-false svg > path {
    fill: #000 !important;
}
.preview-input {
    padding: 12px 16px;
    height: 48px;
    background: #1e232a;
    border: 1px solid #2d3440;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #f5f9ff;
}

.join-room-btn {
    padding: 8px 16px;
    height: 48px;
    background: #12b6bc !important;
    border: 1px solid #12b6bc !important;
    border-radius: 8px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: center;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.98) !important;
    font-family: 'Manrope-Regular';
}

.interviewer-footer {
    min-height: 0;
    width: 70%;
    position: absolute;
    bottom: 0;
    background: #191924;
    z-index: 2;
}
.interviewer-footer2 {
    width: 100%;
    min-height: 0;
    position: absolute;
    bottom: 0;
    background: #191924;
}

.video-footer-btn button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 36px;
    border: 1px solid #2d3440;
    border-radius: 5px;
    color: #fff;
}

.video-footer-btn .hms-ui-c-cHVKOV-egOeBJ-active-false {
    background: #f2464b !important;
}

.end-call {
    float: right;
}

.text-limit {
    text-align: initial;
    margin-top: 5px;
}

.code-title {
    vertical-align: middle;
    line-height: 40px;
    display: inline-block;
    width: 70%;
}

.select-container-rating {
    display: inline-block;
    width: 30%;
}

.skill-ratingv2 {
    background-color: initial;
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    color: #fff;
    height: 38px;
    margin: 15px 0px 10px 0px;
    width: 20%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    font-family: 'Manrope-Bold';
    text-align: center;
}

.fr-checkbox-container > label {
    display: inline-block;
    align-items: center;
    color: #ffffff !important;
    font-size: 14px !important;
    width: 48%;
    text-align: left;
    font-family: 'Manrope-SemiBold';
}

.fr-checkbox-container3 > label {
    display: inline-block;
    align-items: center;
    color: #ffffff !important;
    font-size: 12px !important;
    width: 33.33%;
    text-align: left;
    margin: 10px 0;
}

.final-remarks-input {
    border: 1px solid white;
}
.final-remark-label-box {
    display: flex;
    justify-content: space-between;
}

.final-remark-label-box > .final-remark-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: white;
}

.final-remark-label-box > .final-remark-file-template {
    color: #13b1b6;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.final-remarks-file-input {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid rgba(159, 162, 172, 0.5);
    color: #9ea1ab;
    padding: 5px 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.final-remarks-file-input > .final-remark-input-name {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 1;
}

.final-remarks-file-input > .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    z-index: 0;
    visibility: hidden;
}

.text-right {
    text-align: right;
}

.nsq-component {
    position: relative;
}
.nsq-delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.2px solid #ec2d38;
    border-radius: 50%;
    padding: 2px;
}
.sq-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    margin: 20px 10px;
}
.sqc-container {
    height: 50%;
}

.monaco-editor .margin {
    background-color: #191925 !important;
}
.monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
    background-color: #191925 !important;
}

.side-panel-candidate {
    width: 100%;
    background: #2e2e41;
    border-radius: 8px 8px 0px 0px;
    color: #fff;
    text-align: center;
    padding: 10px;
    top: -55px;
    position: relative;
}
.hms-ui-c-iqqwao {
    padding: 0px !important;
}

.wrapper {
    background: transparent !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
    display: flex;
}
.loader {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 5px solid #000;
    border-top-color: #ffffff;
    box-sizing: border-box;
    background: transparent;
    animation: loading 1s linear infinite;
    margin: auto;
}
.new-loader {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top-color: #000;
    box-sizing: border-box;
    background: transparent;
    animation: loading 1s linear infinite;
    margin: auto;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    0% {
        transform: rotate(360deg);
    }
}

.other-skills-input {
    font-style: normal;
    font-family: 'Manrope-Bold';
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #ffffff;
    box-sizing: border-box;
    padding: 6px 10px;
    border: 1px solid #9b9b9b;
    border-radius: 5px;
    background: #2e2e41;
    display: inline-block;
    float: left;
    text-align: left;
    width: 40%;
    height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.other-skill-rating {
    align-items: center;
    background-color: initial;
    border: 1.2px solid #9b9b9b;
    border-radius: 5px;
    color: #fff;
    display: flex;
    float: left;
    font-family: 'Manrope-Bold';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 38px;
    letter-spacing: 0.05em;
    line-height: 18px;
    margin: 0 1%;
    width: 58%;
}

.text-end {
    text-align: end;
}

.add-text-option {
    color: #46b2b7;
    cursor: pointer;
}

.w-100 {
    width: 100%;
}
.w-70 {
    width: 70%;
}
.w-98 {
    width: 98%;
}
.pr-5 {
    padding-right: 5px;
}
.pl-5 {
    padding-left: 5px;
}
.pt-5 {
    padding-top: 5px;
}
.pb-5 {
    padding-bottom: 5px;
}

.text-align-right {
    text-align: end;
}

.question-bank-container {
    height: 100%;
    position: relative;
    background: #2e2e41;
}
.question-bank-sub-container {
    background: #191924;
    margin: 10px;
    padding: 10px;
    height: 97%;
}

.qb-dropdown {
    background-color: #2e2e41;
    padding: 5px;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 11px;
    width: 100%;
    height: 38px;
}
.qb-search-container {
    background-color: #2e2e41;
    display: flex;
    margin: 10px 0;
}
.qb-search {
    width: 15px;
    margin: 10px;
    height: auto;
}
.qb-search-input {
    background-color: transparent;
    width: calc(100% - 15px);
    padding: 0 10px;
    outline: none;
    color: white;
}
.qb-list-container {
    height: calc(100% - 85px);
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
}
.qb-list {
    background-color: #2e2e41;
    display: flex;
    margin-bottom: 10px;
    width: calc(100% - 30px);
    padding: 4px;
}
.qb-list-btn {
    background-color: #13b1b6;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
}
.qb-list-btn-disabled {
    background-color: #626262;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    cursor: default !important;
}

.qb-list-plus {
    width: 10px;
}
.qb-list-correct {
    width: 15px;
}
.f-13 {
    font-size: 13px;
}

#TD-PrimaryTools {
    background: #191924 !important;
}
#TD-Zoom,
#TD-Styles {
    color: #ffffff !important;
}
.c-lkmCgW,
.c-bUEyht,
.c-eyvddw {
    background: #191924 !important;
    border: none !important;
}
.c-itYKbw {
    display: none !important;
}

.suspected-div {
    text-align: center;
    display: flex;
    color: #fff;
    font-size: 14px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    margin: 20px 0px;
}

.react-switch-bg {
    background: rgb(25 25 37) !important;
}

.css-feonkk-Input2 {
    color: #ffffff !important;
    align-items: center;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 18px;
}
.toggle-switch input[type='checkbox']:checked + .switch:before {
    background-color: #ffffff;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
}
.toggle-switch .switch:before {
    background-color: #ffffff;
    border-radius: 50%;
    content: '';
    height: 22px;
    position: absolute;
    top: -1px;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition:
        transform 0.3s ease,
        -webkit-transform 0.3s ease;
    width: 22px;
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
}

.toggle-switch input[type='checkbox']:checked + .switch {
    background-color: #12b6bc;
}

.toggle-switch .switch {
    background-color: #626262;
    border-radius: 1.2em;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0px;
    margin: 0px;
    width: 40px;
    transition: background-color 0.2s ease;
}

.toggle-switch input[type='checkbox'] {
    display: none !important;
}
.upload-wrapper {
    display: flex;
    gap: 10px;
}
input[type='file'].upload-video {
    display: none;
}
.upload-img-size {
    width: 24px;
}
.video-uploading {
    font-size: 10px;
    color: #ff0000;
    top: 20px;
    left: 0px;
    font-weight: 500;
    text-wrap: nowrap;
}
.upload-img-file {
    display: none;
}
.upload-img-div img {
    width: 20px;
    align-items: center;
    background: #13b1b6;
    border-radius: 5px;
    display: flex;
    float: right;
    height: 25px;
    justify-content: center;
    padding: 5px;
    width: 40px;
    margin-top: 18px;
}

.login-limit-text {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.25px;
    color: #ffffff;
    text-align: center;
}
.login-limit-text2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #9396a0;
    text-align: center;
}

.p-30 {
    padding: 30px;
}
.login-limit-div {
    background-color: #2e2e41;
    justify-content: center;
    height: 100vh;
    padding: 0px 4%;
    display: flex;
    flex-direction: column;
}

.thankyou-joining {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #a0e0e3;
}

.error-page-new-div {
    display: flex;
    flex-direction: row;
    height: 60vh;
    justify-content: center;
}

.error-text-main {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    padding: 20px;
}

.error-btn {
    padding: 8px 27px;
    gap: 12px;
    width: 160px;
    height: 38px;
    background: #ffffff;
    border-radius: 6px;
}

.error-text1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: #9396a0;
    margin-bottom: 40px;
}
.error-text2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 40px;
}
.error-text2 img {
    margin-right: 10px;
}
.error-text3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #fff5f5;
    margin: 10px 0px;
}
.iframe-wrapper {
    align-items: center;
    background: #fff;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    height: 82vh;
}
.iframe-container {
    z-index: 2;
    position: relative;
    border: none;
    margin: 0;
    padding: 0;
    height: 82vh;
}
.iframe-loader {
    -webkit-animation: loading 1s linear infinite;
    animation: loading 1s linear infinite;
    background: transparent;
    border: 5px solid #191924;
    border-radius: 50%;
    border-top-color: #fff;
    box-sizing: border-box;
    height: 3rem;
    margin: auto;
    width: 3rem;
}
.instruction-div {
    padding: 10px;
    margin: 10px 5px;
    background: #20202d;
}
.css-1u9des2-indicatorSeparator {
    width: 0px !important;
}
.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
    padding: 4px !important;
}
.css-1xc3v61-indicatorContainer svg,
.css-15lsz6c-indicatorContainer svg {
    width: 14px !important;
}

.w-58 {
    width: 58%;
    margin: 0px 0.5%;
}
.w-64 {
    width: 64%;
    margin: 0px 0.5%;
}
.w-69 {
    width: 69%;
    margin: 0px 0.5%;
}
.w-18 {
    width: 18%;
    margin: 0px 0.5%;
}
.w-23 {
    width: 23%;
    margin: 0px 0.5%;
}
.w-29 {
    width: 29%;
    margin: 0px 0.5%;
}
.w-35 {
    width: 35%;
    margin: 0px 0.5%;
}
.title-softskillsv {
    font-style: normal;
    font-size: 11px;
    line-height: 15px;
    align-items: center;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 6px 10px;
    text-align: left;
    width: 40%;
    color: #c0c4cd;
}
.si-row-4 {
    height: 30px;
    padding: 0 0 10px;
}

.overall-height {
    margin-top: 10px;
}
.upload-img-div {
    position: absolute;
    right: 20px;
    margin-top: -55px;
    cursor: pointer;
}
.clear-input {
    position: absolute;
    right: 10px;
    top: 3px;
    color: red;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
    line-height: 10px;
}
.length-count {
    position: absolute;
    right: 10px;
    bottom: 6px;
    color: red;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 1px;
}
.length-count2 {
    position: absolute;
    right: 10px;
    bottom: 6px;
    color: #61be52;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 1px;
}
.input-textarea {
    position: relative;
}
.hms-ui-c-dyyEyr-dnCwUz-variant-standard {
    padding: 10px !important;
    border-radius: 2px !important;
}
.hms-ui-c-dyyEyr-dnCwUz-variant-standard::before {
    display: none !important;
}
.hms-ui-c-iyBJoc-etOMTx-variant-sub1 {
    color: #fff !important;
    font-size: 12px !important;
    letter-spacing: 0.05em !important;
    line-height: 18px !important;
    text-align: center !important;
    font-family: 'Manrope-SemiBold' !important;
    padding-left: 10px !important;
}
.hms-ui-c-cOcQin-iejOyQS-css {
    top: 10px !important;
    left: 10px !important;
    right: auto !important;
    bottom: auto !important;
}

.code-editor-div2 .css-b62m3t-container {
    background: transparent;
}
.code-editor-div2 .css-13cymwt-control,
.code-editor-div2 .css-t3ipsp-control {
    background: #3c3c3c !important;
    border: none !important;
    width: 250px !important;
    margin-right: 10px;
}

.monaco-editor .view-overlays .current-line {
    border: none !important;
}
.decorationsOverviewRuler {
    display: none !important;
}
.pos-rel {
    position: relative;
}
.select-container-rating {
    font-size: 13px;
    text-align: left;
}
.select-input-other {
    font-size: 13px;
    text-align: left;
}
.new-message-type2 a,
.new-message-type1 a {
    color: #fff !important;
}
.new-message-type1:after {
    z-index: 10000000;
    transform: rotate(-180deg);
    position: absolute;
    top: 7px;
    content: '';
    width: 0;
    height: 0;
    border-top: 15px solid rgba(25, 147, 147, 0);
    border-left: 15px solid #2e2e41;
    left: -12px;
}
.new-message-type2:after {
    z-index: 10000000;
    transform: rotate(180deg);
    position: absolute;
    top: 7px;
    content: '';
    width: 0;
    height: 0;
    border-top: 15px solid rgba(25, 147, 147, 0);
    border-right: 15px solid rgb(24 176 182);
    right: -12px;
}
.c-bUEyht-jvfJsl-side-right > button:nth-child(1),
.c-bUEyht-jvfJsl-side-right > button:nth-child(2),
#TD-PrimaryTools > span:last-child,
#TD-ContextMenu {
    display: none;
}
.recordingDiv video {
    width: 80% !important;
    height: 80% !important;
    margin: 20px;
}
.deleteImg {
    position: absolute;
    font-size: 12px;
    color: #ec2d39;
    margin-top: -12px;
    margin-left: -6px;
    border: 1px solid #ec2d39;
    line-height: 11px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}
.codeImages img {
    width: 100px;
    height: 100px;
    display: inline-block;
}
.codeImages {
    width: 110px;
    display: inline-block;
    height: 100px;
    margin: 10px 5px;
}
.logout-btn {
    background: #fff;
    border-radius: 6px;
    gap: 12px;
    height: 38px;
    padding: 8px 27px;
    margin: 0 auto;
    width: 200px;
}
.zoom-in-out {
    background: #0009;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    width: 20px;
    z-index: 2;
}
#tnc-permission,
#consent-permission {
    width: 25px;
    height: 25px;
}
.update-feedback-container {
    width: 100%;
    margin: 0 auto;
    display: block;
    height: 75vh;
}
.feedback-container-v2 {
    position: absolute;
    width: 30%;
    right: 0;
}
.video-timer {
    width: 90px !important;
    font-size: 14px;
    background-color: #f0464c !important;
}

.MiniScreenShotDiv {
    background: #2e2e41;
    border-radius: 10px 10px 0 0;
    display: inline-block;
    margin: 5px;
    padding: 5px;
    /* width: 250px; */
    text-align: center;
    /* max-width: 250px; */
}
.ScreenShotHeader {
    text-align: end;
}
.ScreenShotHeader img {
    margin: 2px 10px;
}
.ScreenShotBody img {
    width: 240px;
    height: 240px;
}
.tool-tip-div {
    background: #13161b;
    border-radius: 8px;
    position: absolute;
    padding: 4px;
    width: 180px;
}
.tool-tip-title {
    text-align: center;
    letter-spacing: 0.5px;
    color: #12b6bc;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}
.tool-tip-message {
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #e6e9f8;
}
.screenshot-div {
    background: #191924;
    overflow-y: scroll;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding: 40px 0px;
    width: 100%;
    /* display: grid;
  gap: 1rem; */
}
.miniScreenshotDiv_container {
    display: grid;
    gap: 0.75rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0.75rem;
}
.tool-tip-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #14161b;
    height: 0;
    left: calc(50% - 10px);
    position: absolute;
    width: 0;
    z-index: 1;
    bottom: -8px;
}
.tip-change {
    margin-top: -55px;
    margin-left: 132px;
}
.tip-set-main {
    margin-top: -55px;
    margin-left: 96px;
}
.tip-delete {
    margin-top: -55px;
    margin-left: 60px;
}
.tip-edit {
    margin-top: -55px;
    margin-left: 22px;
}

.crop-popup-container {
    position: fixed;
    background-color: #2e2e41;
    border-radius: 10px;
    height: 400px;
    left: calc(35% - 250px);
    padding: 20px;
    top: calc(50% - 200px);
    max-width: 500px;
    width: 100%;
}

.cp-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.cp-header-title {
    font-size: 18px;
    font-weight: 600;
}

.cp-header-img-container {
    display: flex;
    align-items: center;
}

.cp-header-img-1 {
    height: 18px;
    width: auto;
    cursor: pointer;
}

.cp-header-img-2 {
    height: 13px;
    width: auto;
    margin-left: 10px;
    cursor: pointer;
}

.cp-crop-img-container {
    height: calc(100% - 50px);
    margin-top: 20px;
    overflow: hidden;
    background-color: #000000;
}

.cp-crop-img,
.ReactCrop,
.ReactCrop__child-wrapper {
    height: 100% !important;
    width: auto !important;
}

.small-popup {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 350px;
    width: 100%;
    border-radius: 10px;
    top: calc(50% - 175px);
    left: calc(35% - 175px);
    color: white;
    background-color: #1e232a;
    padding: 20px;
}

.small-popup-img {
    height: 80px;
    width: auto;
    margin-bottom: 10px;
}

.sp-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.sp-subtitle {
    font-size: 14px;
    margin-bottom: 10px;
}

.sp-btn-container {
    display: flex;
    align-items: center;
}

.sp-btn {
    border: none;
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 5px;
    font-size: 15px;
    width: 100px;
}

.sp-yes {
    background-color: #f2464b;
}

.sp-cancel {
    background-color: #626262;
}

/* .fup-title {
  font-size: 32px;
  font-weight: 600;
  color: white;
  margin: 10px 0;
} */

.dropzone {
    background-color: #191924;
    height: 200px;
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    cursor: pointer;
}

.main-popup-container {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    /* width: 70%; */
    width: 100%;
}
.crop-popup-container-2 {
    position: absolute;
    background-color: #2e2e41;
    border-radius: 10px;
    /* left: calc(50% - 250px); */
    left: calc(35% - 250px);
    padding: 20px;
    top: calc(50% - 200px);
    max-width: 500px;
    max-height: 410px;
    overflow: auto;
    width: 100%;
    text-align: center;
}

.fup-subtitle {
    font-size: 16px;
    color: white;
    margin: 10px 0;
}

.fup-text-theme-peacock {
    color: #12b6bc;
    text-decoration: underline;
}

.fup-file-type {
    color: #676767;
    font-size: 12px;
}

.fup-btn {
    font-size: 14px;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px;
    max-width: 300px;
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.fup-btn-active {
    background-color: #12b6bc;
}

.fup-btn-inactive {
    background-color: #626262;
}

.fup-uploaded-container {
    width: 300px;
    margin: auto;
    text-align: start;
}

.fup-uploaded-title {
    color: white;
    font-size: 12px;
}

.fup-file {
    padding: 10px;
    font-size: 12px;
    background-color: white;
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fup-file-name {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remove-file-btn {
    cursor: pointer;
}

.css-1cfo1cf input {
    color: #fff !important;
}

.wrapper-4 {
    align-items: center;
    background: #2e2e41ad;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9998;
}
.status-show {
    display: block;
    margin: inherit;
    position: relative;
    text-align: right;
}
.status-show1 {
    display: block;
    margin: inherit;
    position: absolute;
    text-align: right;
    right: 10px;
}
.feedback-header-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5.32vw;
}
.react-select__menu {
    max-height: 200px;
    /* Set your desired height here */
}
.ext-file-download {
    top: 5px;
    position: relative;
}
.screenshot-div-v2 {
    overflow-y: scroll;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 20px;
    color: rgb(147, 150, 160);
    background: rgb(25, 25, 36);
    width: 98%;
    height: 80vh;
    margin: 0px auto;
    padding: 10px;
}
.ScreenShotBody {
    position: relative;
    display: block;
    margin: 10px 0px;
}

.select-status-red {
    color: #fd050d;
}
.select-status-orange {
    color: #ff822e;
}
.select-status-green {
    color: #50e55f;
}
.select-status-text {
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    text-align: center;
    letter-spacing: 0.05em;
}
.select-status-rating {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.05em;
}
.css-1tkm9rf-control {
    background: #2e2e41 !important;
    background-color: #2e2e41 !important;
    color: #fff;
    border: 1px solid #9b9b9b !important;
}
.css-5hvlph-control {
    background-color: #2e2e41 !important;
    text-align: center !important;
    border: 1px solid #9b9b9b !important;
}
.comment-skill-div {
    color: #13b1b6;
    border-radius: 5px;
    margin: 0 0.5%;
    font-size: 12px;
    font-family: 'Manrope-SemiBold';
}
.rating-system {
    display: flex;
    justify-content: space-evenly;
    margin: 40px 0px;
    height: 60px;
}
.hyperlink {
    text-decoration: underline;
    color: #0d6efd;
}

::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #191924;
}

.mob-feedback-btn {
    border: 1px solid #2d3440;
    width: 40px;
    height: 40px;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mob-feedback-on {
    background-color: #12b6bc;
}

.mob-feedback-off {
    background-color: transparent;
}

.mob-feedback-logo {
    width: 19px;
    height: auto;
}

.tab-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    color: white;
}

.preview_title_container {
    aspect-ratio: 16/11;
}
.verification_text {
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 3vw;
    text-align: center;
    color: #ffffff;
    margin-top: 3.5vw;
    margin-bottom: 7vw;
}
.screenshot-div-v2 .miniScreenshotDiv_container {
    padding: 0;
}
@media only screen and (max-width: 992px) {
    .verification-p {
        font-size: 18px;
    }
    .join-room-btn {
        font-weight: 400 !important;
        font-size: 14px !important;
    }
    .tnc-container {
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .video-footer {
        flex-wrap: wrap !important;
    }
    .meeting-tab {
        text-align: end;
        width: calc(100% - 50px);
        position: absolute;
        top: 10px;
    }
    .candidate-meeting-tab {
        text-align: end;
        width: calc(100% - 50px);
        position: absolute;
        top: 10px;
    }
    .interviewer-footer {
        width: 100%;
    }
    .feedback-container {
        height: calc(100% - 48px);
    }
    .fr-checkbox-container > label {
        font-size: 12px !important;
    }
    .run-code-btn {
        width: 34px;
    }
    .run-code-btn > img {
        margin-right: 0px;
    }
    .mobile-view-page {
        height: 100%;
        border: 2px solid white;
    }
    .mobile-view-header {
        border: 1.5px solid green;
        padding: 10px 20px;
    }
    .incruiter-mobile-logo {
        width: 75px;
        height: auto;
    }
    .mobile-view-body {
        border: 1.5px solid red;
        height: calc(100% - 150px);
    }
    .mobile-view-footer {
        border: 1.5px solid green;
    }
    .preview_main_container {
        width: 50vw;
    }
}
@media only screen and (max-width: 768px) {
    .preview_title_container {
        aspect-ratio: unset;
    }
    .crop-popup-container,
    .small-popup,
    .crop-popup-container-2 {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .w-md-100 {
        width: 100% !important;
    }
}
@media only screen and (max-width: 548px) {
    .code-editor-main-div {
        height: calc(100% - 90px);
    }
    .code-editor-div3 {
        justify-content: space-evenly;
        margin: 10px 0 0 0;
        width: 100%;
    }
    .preview_title_container {
        /* min-height: 360px;
    max-height: 360px; */
        max-height: calc(100vh - 8rem);
    }
    .preview-div {
        margin-top: 2rem;
    }
    .video-footer-btn .video-footer {
        gap: 9px;
    }
}

.resume-summary {
    padding: 20px !important;
    text-align: left !important;
}
@media (min-width: 548px) {
    .miniScreenshotDiv_container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 900px) {
    .miniScreenshotDiv_container {
        grid-template-columns: repeat(3, 1fr);
    }
    .screenshot-div-v2 .miniScreenshotDiv_container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 1200px) {
    .miniScreenshotDiv_container {
        grid-template-columns: repeat(4, 1fr);
    }
    .screenshot-div-v2 .miniScreenshotDiv_container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 768px) and (max-height: 570px) {
    .tnc-container {
        overflow-y: scroll;
    }
    .preview_main_container {
        width: 50vw !important;
    }
}

.interviewer-room {
    width: 100% !important;
}
.candidate-room {
    width: 100% !important;
}

#wc-container-right {
    position: fixed !important;
    z-index: 999 !important;
}
.join-dialog {
    width: 100% !important;
}

/* verification css fixes */
.verification_early_join_div.early-join-div {
    height: 95vh;
}
/* .verification_early_join_div .verification_text {
    font-size: 18px;
    margin-bottom: 40px;
    margin-top: 70px;
} */
.d-none {
    display: none !important;
}
.wth-98 {
    width: 98%;
}

.meeting-tab-2 {
    position: absolute;
    text-align: end;
    top: 32px;
    width: 100%;
}
.on-hold-main .wr-main-content,
.on-hold-main .wr-footer {
    display: none;
}
.meeting-app {
    width: 100%;
    height: 100%;
}

.exit-feedback-title {
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
    width: 100%;
}

.exit-feedback-select {
    width: 100%;
    font-size: 13px;
    text-align: left;
    display: inline-block;
    margin-bottom: 5px;
}

.exit-input-disabled {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #fff;
    background: transparent;
    width: 60%;
    float: left;
}
.f-right {
    float: right;
}
.exit-input-field {
    display: flex;
    padding: 6px 10px;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(155 155 155);
    font-style: normal;
    font-weight: 275;
    line-height: normal;
    letter-spacing: 0.6px;
    background: #2e2e41;
    color: #fff;
    font-size: 12px;
}
.beam-page .hms-ui-c-keULYD {
    width: 20px !important;
    height: 20px !important;
    padding: 4px !important;
}
.beam-page .hms-ui-c-iyBJoc-ikNQUuY-css {
    font-size: 9px !important;
    padding: 2px !important;
}
.beam-page .hms-ui-c-dLTrkE {
    border-radius: 2px !important;
}
.delete-final-btn {
    background-color: #ec2d39 !important;
}
.react-split__sash--vertical {
    background-color: #000;
    width: 10px !important;
    margin-top: 38px;
    height: calc(100% - 38px) !important;
    border-radius: 2px;
}
.react-split__sash--vertical .split-sash-content {
    height: 30px;
    content: url('assets/images/resizable-icon.png');
    /* background: gray; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: e-resize;
}
.split-sash-content-active {
    background-color: transparent !important;
}
.react-split__sash--horizontal {
    height: 10px !important;
    width: 100% !important;
    background-color: #000;
    border-radius: 2px;
}
.react-split__sash--horizontal .split-sash-content {
    content: url('assets/images/resizable-horizontal.png');
    position: absolute;
    left: 50%;
    width: 30px;
    transform: translateX(-50%);
}
.arrow_icon_container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #13b1b6;
    position: absolute;
    top: 20%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.arrow_icon_container.rightOpened {
    right: 28.7%;
}
.arrow_icon_container.rightClosed {
    right: 0.7%;
}
.position-relative {
    position: relative;
}
.sidebar-back-arrow,
.sidebar-right-arrow {
    filter: brightness(0) invert(1);
    height: 1.5em;
    width: auto;
}

.side-bg {
    width: 2%;
    background: #191924;
    height: 100%;
    position: absolute;
    right: 0;
}
.rotate-180 {
    transform: rotate(180deg);
}
.message-text.green {
    color: #018d3a !important;
    background-color: initial !important;
}

.message-text.orange {
    color: #f49454 !important;
    background-color: initial !important;
}

.message-text.red {
    color: #f2464b !important;
    background-color: initial !important;
}

.proctoring-message-circle.green {
    background-color: #018d3a !important;
}

.proctoring-message-circle.orange {
    background-color: #f49454 !important;
}

.proctoring-message-circle.red {
    background-color: #f2464b !important;
}
.start-proctoring {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.proctoring-message-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}
.proctoring-message-text {
    flex-grow: 1;
    border-bottom: 1px solid #626262;
    padding: 10px;
}
.proctoring-message {
    max-width: 500px;
    margin: 15px auto;
}
.proctoring-screenshots {
    max-width: 960px;
    margin: 15px auto;
    text-align: center;
}
.screenshots-proctoring {
    display: inline-block;
    width: 280px;
    border-radius: 10px 10px 0px 0px;
    background: #e9e9e9;
    margin: 10px;
    font-size: 14px;
}
.screenshots-description,
.screenshots-proctoring img {
    width: 280px;
    margin: auto;
    text-align: left;
}
.input-disabled div[class^=' css-'][class$='-indicatorContainer'],
.input-disabled span[class^=' css-'][class$='-indicatorSeparator'] {
    display: none !important;
}
#TD-Tools-Dots {
    display: none;
}
.video_container {
    position: relative;
    width: 80%;
    text-align: center;
}
.video_container img {
    position: absolute;
    right: 25%;
    top: 40px;
    cursor: pointer;
}
.add_screenshot_btn.feedback-btn {
    font-size: 12px;
    width: auto;
    color: #fff;
    padding: 5px 10px;
    border-radius: unset;
    margin-left: auto;
    position: absolute;
    right: 0;
    z-index: 2;
}
.miniScreenshotDiv_container {
    margin-top: 70px;
}
.tandScontainer {
    display: grid;
    grid-template-columns: 59% 40%;
    grid-column-gap: 10px;
    margin-left: 1.5rem;
}
.tandScontainerMain {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
    margin-left: 1.5rem;
}
.tandScontainerMainStyle {
    margin: 0px;
    padding: 10px;
    padding-right: 20px;
}
.tandScontentContainer {
    background-color: #2e2e41;
    padding: 11px 6px;
}
.no-transcript-parent {
    padding: 5px;
    overflow-y: auto;
    height: calc(100vh - 120px);
}
.no-transcript-div {
    width: 100%;
    background-color: #191924;
    border: 1px solid black;
    padding: 3px 10px 3px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.generate-transcript-btn {
    background-color: #13b1b6;
    border-radius: 5px;
    display: flex;
    height: 30px;
    width: 145px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
}
.disable-transcript-btn {
    background-color: #626262;
}
.common-btn.ai_btn {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 18px;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    height: 26px;
    max-width: max-content;
}
.common-btn.upload_btn {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 5%;
    line-height: 24px;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    height: 34px;
    min-width: 184px;
    width: 100%;
    background-color: #133f7d;
}
.common-btn.upload_btn.regenerate {
    background-color: #12b6bc;
}
.common-btn.ai_btn.disabled,
.common-btn.upload_btn.regenerate:disabled {
    background-color: #626262;
}
.me-1 {
    margin-right: 0.5rem;
}
.c-QTiTL,
.c-buzxUF,
.c-iEBYAJ {
    background: #191924 !important;
    border: none !important;
}
.c-buzxUF-gfWoeZ-cv .c-iEBYAJ {
    background: #46b2b7 !important;
}
#TD-TopPanel-Undo,
#TD-TopPanel-Redo {
    display: none !important;
}
.tandScontentContainer p span {
    font-family: 'Poppins' !important;
}
.skill-rating-div.mb-0 {
    margin-bottom: 0;
}
.canceled_icon {
    margin-bottom: 30px;
    margin-top: -4rem;
    width: 8.8vw !important;
    height: 16vh !important;
}
.tnc-content-text.mt-0 {
    margin-top: 0;
}
.exit-icon {
    width: 1.6vw;
}
.feedback-inner-div-p {
    font-size: 0.75vw;
}
.star-img {
    width: 2vw;
    height: 6.5vh;
}
.chat-heading {
    font-size: 1.1vw !important;
}
.no-messages-chat-screen {
    font-size: 0.85vw !important;
}
.chat-messages {
    font-size: 0.64vw;
}
.chat-message-sender {
    font-size: 0.75vw !important;
}
.cancelled-interview-msg {
    font-size: 1.7vw !important;
}
.clock-img {
    width: 6.4vw;
}
.feedback-container {
    height: calc(100% - 8vh);
}
.flex-column {
    flex-direction: column;
}
.uploading_text {
    font-size: 10px;
    font-weight: 500;
    color: #ff0000;
    line-height: 13.66px;
    margin-top: 10px;
    position: absolute;
}
.privacy-policy {
    display: inline;
    color: #007bff;
    text-decoration: none;
    margin-right: 10px;
}
.position-absolute {
    position: absolute;
}
.disabled {
    background-color: #626262;
}
@media (min-width: 1400px) {
    .tab-text {
        font-size: 0.75vw;
    }
    .feedback-inner-head {
        font-size: 1vw;
    }
    .feeback-collection-header {
        font-size: 1.2vw;
    }
    .feedback-inner-div-p {
        font-size: 0.75vw;
    }
    .star-img {
        width: 2vw;
        height: 6.5vh;
    }
    .feedback-submit {
        width: 3.8vw;
        height: 5vh;
        font-size: 0.75vw;
    }
    .chat-heading {
        font-size: 1.1vw !important;
    }
    .no-messages-chat-screen {
        font-size: 0.85vw !important;
    }
    .chat-messages {
        font-size: 0.64vw;
    }
    .chat-message-sender {
        font-size: 0.75vw !important;
    }
    .cancelled-interview-msg {
        font-size: 1.7vw !important;
    }
    .earlyjoin-p {
        font-size: 1.6vw;
    }
    .clock-img {
        width: 6.4vw;
    }
    .tab {
        width: 7.8vw;
        font-size: 0.6vw;
    }
}
@media (max-width: 1400px) and (min-width: 1200px) {
    .feedback-header-title {
        width: 5.32vw;
    }
    .feedback-inner-head {
        font-size: 1.2vw;
    }
    .feeback-collection-header {
        font-size: 1.5vw;
    }
    .feedback-inner-div-p {
        font-size: 0.9vw;
    }
    .star-img {
        width: 2.5vw;
    }
    .feedback-submit {
        width: 5vw;
        font-size: 0.9vw;
    }
    .chat-heading {
        font-size: 1.4vw !important;
    }
    .no-messages-chat-screen {
        font-size: 0.9vw !important;
    }
    .chat-messages {
        font-size: 0.8vw;
    }
    .chat-message-sender {
        font-size: 1vw !important;
    }
    .cancelled-interview-msg {
        font-size: 2vw !important;
    }
    .earlyjoin-p {
        font-size: 2.2vw;
    }
    .clock-img {
        width: 7vw;
    }
    .tab {
        width: 8vw;
        font-size: 0.65vw;
    }
    .admin_tab.activity-tab {
        height: 5.1vh;
        width: 8.5vw;
    }
    .admin_tab.activity-tab .tab-logo {
        height: 2.6vh;
        width: 1.2vw;
    }
    .tcn-accetp-btn {
        font-size: 12px !important;
        height: 32px;
        line-height: 20px !important;
        width: 90px;
        font-weight: 400 !important;
        border-radius: 5px !important;
    }
}
@media (max-width: 1399px) {
    .tnc-span {
        font-size: 16px;
        line-height: 24px;
    }
    .tnc-content-header {
        font-size: 14px;
        line-height: 22px;
    }
    .tnc-content-span {
        font-size: 12px;
        line-height: 20px;
    }
    .tnc-content-text {
        font-size: 10px;
        line-height: normal;
    }
    .tnc-wrapper {
        gap: 15px !important;
    }
}
@media (max-width: 1200px) {
    .tcn-accetp-btn {
        font-size: 12px !important;
        height: 32px;
        line-height: 20px !important;
        width: 90px;
        font-weight: 400 !important;
        border-radius: 5px !important;
    }
    .verification-p {
        font-size: 3vw;
        line-height: 4vw;
        margin: 3vw;
    }
    .verification_text {
        font-size: 2vw;
        line-height: 5vw;
        margin-top: 5vw;
        margin-bottom: 10vw;
    }
    .verification-icon {
        width: 20%;
        height: auto;
    }
    .feedback-header-title {
        width: 5.32vw;
    }
    .feedback-inner-head {
        font-size: 1.5vw;
    }
    .feeback-collection-header {
        font-size: 1.8vw;
    }
    .feedback-inner-div-p {
        font-size: 1.1vw;
    }
    .star-img {
        width: 3vw;
    }
    .feedback-submit {
        width: 6.6vw;
        font-size: 1.1vw;
    }
    .chat-heading {
        font-size: 1.6vw !important;
    }
    .no-messages-chat-screen {
        font-size: 1.1vw !important;
    }
    .chat-messages {
        font-size: 1vw;
    }
    .chat-message-sender {
        font-size: 1.18vw !important;
    }
    .cancelled-interview-msg {
        font-size: 2.2vw !important;
    }
    .earlyjoin-p {
        font-size: 2.5vw;
    }
    .clock-img {
        width: 7.2vw;
    }
    .tab {
        width: 8.5vw;
    }
}
@media (max-width: 578px) {
    .tnc-wrapper {
        width: 97vw;
        max-width: 97%;
        gap: 20px;
    }
    .tnc-container input[type='checkbox'] {
        width: 12px;
        height: 12px;
        transform: scale(1);
    }
    .tcn-cross-img {
        width: 12px;
        height: 12px;
    }
}
@media (max-width: 992px) and (min-width: 579px) {
    .tnc-wrapper {
        width: 80vw;
    }
}

@media (max-width: 992px) {
    .generate-transcript-btn {
        font-size: 10px;
    }
    .tab-logo {
        margin-right: 0;
    }
    .activity-tab {
        width: 36px;
        height: 36px;
        border: 1px solid #2d3440;
        background-color: transparent;
        border-radius: 0.5em;
        margin: 2.5px;
    }
    .feedback-header-title {
        width: 5.32vw;
    }
    .exit-icon {
        width: 2.6vw;
    }
    .feedback-inner-head {
        font-size: 1.8vw;
    }
    .feeback-collection-header {
        font-size: 2vw;
    }
    .feedback-inner-div-p {
        font-size: 1.4vw;
    }
    .star-img {
        width: 3.5vw;
    }
    .feedback-submit {
        width: 7.8vw;
        font-size: 1.4vw;
    }
    .chat-heading {
        font-size: 2vw !important;
    }
    .no-messages-chat-screen {
        font-size: 1.4vw !important;
    }
    .chat-messages {
        font-size: 1.2vw;
    }
    .chat-message-sender {
        font-size: 1.42vw !important;
    }
    .cancelled-interview-msg {
        font-size: 2.5vw !important;
    }
    .earlyjoin-p {
        font-size: 2.6vw;
    }
    .clock-img {
        width: 10vw;
    }
    .tab {
        width: 9.5vw;
        font-size: 0.7vw;
    }
}
@media (max-width: 768px) {
    .verification-p {
        font-size: 4vw;
        line-height: 5vw;
        margin: 3vw;
    }
    .verification_text {
        font-size: 3vw;
        line-height: 6vw;
        margin-top: 7vw;
        margin-bottom: 14vw;
    }
    .feedback-header-title {
        width: 16vw;
    }
    .exit-icon {
        width: 3.6vw;
    }
    .feedback-inner-head {
        font-size: 2vw;
    }
    .feeback-collection-header {
        font-size: 2.2vw;
    }
    .feedback-inner-div-p {
        font-size: 1.6vw;
    }
    .star-img {
        width: 4vw;
    }
    .feedback-submit {
        width: 9vw;
        font-size: 1.6vw;
    }
    .feedback-wrapper-new {
        width: 60%;
    }
    .chat-heading {
        font-size: 2.5vw !important;
    }
    .no-messages-chat-screen {
        font-size: 1.7vw !important;
    }
    .chat-messages {
        font-size: 1.58vw;
    }
    .chat-message-sender {
        font-size: 1.8vw !important;
    }
    .cancelled-interview-msg {
        font-size: 2.8vw !important;
    }
    .canceled_icon {
        width: 11vw !important;
    }
    .earlyjoin-p {
        font-size: 3vw;
    }
    .too-early {
        margin-bottom: 0;
    }
    .clock-img {
        width: 12vw;
    }
    .tab {
        width: 128px;
        font-size: 10px;
    }
}
@media (max-width: 576px) {
    .feedback-header-title {
        width: 16vw;
    }
    .exit-icon {
        width: 4.6vw;
    }
    .feedback-inner-head {
        font-size: 2.2vw;
    }
    .feeback-collection-header {
        font-size: 2.5vw;
    }
    .feedback-inner-div-p {
        font-size: 1.8vw;
    }
    .star-img {
        width: 5vw;
    }
    .feedback-submit {
        width: 10.5vw;
        font-size: 1.8vw;
    }
    .chat-heading {
        font-size: 3vw !important;
    }
    .no-messages-chat-screen {
        font-size: 2.1vw !important;
    }
    .chat-messages {
        font-size: 2.2vw;
    }
    .chat-message-sender {
        font-size: 2.5vw !important;
    }
    .cancelled-interview-msg {
        font-size: 3vw !important;
    }
    .canceled_icon {
        width: 13vw !important;
    }
    .earlyjoin-p {
        font-size: 3.5vw;
    }
    .time-remaining {
        margin-top: 6px;
    }
    .clock-img {
        width: 15vw;
    }
}
@media (max-width: 420px) {
    .feedback-header-title {
        width: 16vw;
    }
    .feedback-inner-head {
        font-size: 2.5vw;
    }
    .feeback-collection-header {
        font-size: 2.8vw;
    }
    .feedback-inner-div-p {
        font-size: 2.2vw;
    }
    .feedback-submit {
        width: 16vw;
        font-size: 2.8vw;
    }
    .chat-heading {
        font-size: 4vw !important;
    }
    .no-messages-chat-screen {
        font-size: 2.6vw !important;
    }
    .chat-messages {
        font-size: 2.95vw;
    }
    .chat-message-sender {
        font-size: 3.2vw !important;
    }
    .cancelled-interview-msg {
        font-size: 3.2vw !important;
    }
    .canceled_icon {
        width: 14vw !important;
    }
    .earlyjoin-p {
        font-size: 4vw;
    }
    .clock-img {
        width: 18vw;
    }
}
@media (max-height: 750px) {
    .hms-ui-c-gTYrdt-ijarHPt-css {
        width: 200px !important;
        height: 250px !important;
    }
    .hms-ui-c-iqqwao-ibmHBEI-css {
        height: 125px !important;
    }
    .hms-ui-c-gTYrdt-ihLhQTS-css {
        height: 128px !important;
    }
}
.qb-dropdowns .qb-dropdown-flex {
    flex: 1;
}
.react-tooltip {
    z-index: 100;
}
.checkbox-container-disabled,
.select-container-rating-disabled {
    opacity: 0.6;
}
.transcript-main-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 10px;
}
.preview-meet .preview-btn {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #2d3440;
}
.meet-join-btn div {
    color: rgba(255, 255, 255, 0.98);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.5px;
}
.preview-meet .meet-disable-btn {
    border-radius: 5px;
    border: 1px solid var(--Border-Light, #2d3440);
    background: var(--InCRuiter-Failed---Red, #f2464b);
}
.preview-meet .meet-disable-btn:hover {
    border-radius: 5px;
    border: 1px solid var(--Border-Light, #2d3440);
    background: var(--InCRuiter-Failed---Red, #f2464b);
}
.preview-meet button.participant_list{
    width: 60px !important;
}
.meet-footer .video-footer-btn button{
    width: 36px;
}
.skill-input-field{
    position: relative;
    width: 64%;
    display: inline-block;
    height: 38px;
    float: left;
}
.d-flex-justify-content-between{
    justify-content: space-between;
    display: flex;
}